import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingFallback = () => {
    return (
        <Backdrop
            sx={{
                color: '#000000', // Loader color
                zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it's above other elements
            }}
            open={true} // Always open while loading
        >
            <CircularProgress
                sx={{ color: '#000000' }} // Apply custom color via sx
            />
        </Backdrop>
    );
};

export default LoadingFallback;